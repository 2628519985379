@use 'variables' as *;

/* media query */
@mixin mq($size) {
  @media screen and (max-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

@mixin mq-min($size) {
  @media screen and (min-width: #{map-get($breakpoints, $size)}) {
    @content;
  }
}

@mixin mqpx($px) {
  @media screen and (max-width: $px) {
    @content;
  }
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
