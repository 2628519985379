/* colors */
$color-primary: #ff9800;
$color-text: #263238;
$color-text-light: #bbb8b8;
$color-anifty-white: #f9fafb;
$color-success: #4bca81;
$color-fail: #ce002a;
$color-link: #2081e2;

$color-twitter-blue: #1da1f2;
$color-opensea-blue: #2081e2;

$color-tag-pink: #C87EBC;
$color-tag-orange: #C89D7E;
$color-tag-purple: #867EC8;
$color-tag-blue: #7EB9C8;
$color-tag-green: #A2C87E;

/* media query breakpoints */
$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1200px,
) !default;

// container width
$container-width: 1194px;
$container-width-wide: 1382px;
$container-width-narrow: 562px;

// header height
$header-height: 80px;
