@use 'variables' as *;
@use 'mixins' as *;

$fontstack: 'Roboto', YakuHanJPs, 'Noto Sans JP', 'Adobe Blank', sans-serif;

.header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: background-color 0.3s ease-out;
  line-height: 32px;
  font-family: $fontstack;
}

.headerTransparent {
  @extend .header;
  background-color: transparent;
}

.btnReset {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.activeLink {
  @include mq('lg') {
    display: none !important;
  }
}

.headerInner {
  height: 64px;
  width: 90%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-out;

  > a svg {
    display: block;
    width: 100px;
  }

  @include mq-min('md') {
    width: 95%;
    height: 64px;

    > a svg {
      width: 100px;
    }
  }

  @include mq-min('lg') {
    width: 95%;
    height: 96px;

    > a svg {
      width: 160px;
    }
  }
}

.headerLang {
  margin: 0 auto 0 16px;
  position: relative;
  line-height: 0;

  @include mq-min('lg') {
    margin: 0 auto 0 24px;
  }
}

.headerLangLabel {
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  padding: 0 8px;
  cursor: pointer;
  border: solid 1px rgba(25, 27, 30, 0.5);
  border-radius: 8px;
}

.headerLangList {
  position: absolute;
  margin-top: 4px;
  margin-left: 0px;
  padding: 8px 16px;
  visibility: hidden;
  opacity: 0;
  border: solid 1px rgba(25, 27, 30, 0.5);
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s ease-out 0.1s;

  > li {
    list-style-type: none;
  }

  > li a {
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    display: block;
    white-space: nowrap;
    letter-spacing: 1px;
    opacity: 0.5;
    color: #191b1e;
    cursor: pointer;
    text-decoration: none;
  }
}

.localeListActive {
  visibility: visible;
  opacity: 1 !important;
}

.headerNav {
  display: flex;
  align-items: center;

  > a {
    font-size: 13px;
    font-weight: 700;
    position: relative;
    color: #191b1e;
    cursor: pointer;
    text-decoration: none;
    font-family: $fontstack;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    @include mq-min('lg') {
      font-size: 15px;
      margin: 0 8px;
    }
  }

  .bell {
    @include mq('md') {
      margin: 0;
    }
  }

  > a svg {
    width: 17px;
    margin: 0 4px 0 16px;
  }

  > a:hover {
    color: #f5be17;
  }
}

.alertBell:hover {
  fill: #f5be17;
}

.headerNavTrigger {
  margin: 0 11.2px 0 16px;
  padding: 4.8px 0 4.8px 8px;
  cursor: pointer;

  @include mq-min('lg') {
    margin: 0 28.8px 0 24px;
    padding: 4.8px 8px;
  }

  > span {
    position: relative;
    display: block;
    width: 6.4px;
    height: 6.4px;
    transition: background-color 0.15s ease-out;
    border-radius: 100%;
    background-color: #191b1e;
  }

  > span::before {
    left: -11.2px;
    transition: all 0.15s ease-out, left 0.15s ease-out 0.15s;
    position: absolute;
    display: block;
    width: 6.4px;
    height: 6.4px;
    content: '';
    border-radius: 100%;
    background-color: #191b1e;
  }

  > span::after {
    right: -11.2px;
    transition: all 0.15s ease-out, right 0.15s ease-out 0.15s;
    position: absolute;
    display: block;
    width: 6.4px;
    height: 6.4px;
    content: '';
    border-radius: 100%;
    background-color: #191b1e;
  }
}

.headerNavTrigger:hover span::after {
  right: -9.6px;
}

.headerNavTrigger:hover span::before {
  left: -9.6px;
}

.headNavTriggerActive {
  cursor: pointer;
  margin: 0 11.2px 0 16px;
  padding: 4.8px 0 4.8px 8px;

  @include mq-min('lg') {
    margin: 0 28.8px 0 24px;
    padding: 4.8px 8px;
  }

  > span {
    transition: background-color 0.15s ease-out 0.15s;
    background-color: transparent;
    position: relative;
    display: block;
    width: 6.4px;
    height: 6.4px;
    border-radius: 100%;
  }

  > span::before {
    left: 0;
    transition: all 0.15s ease-out 0.15s, left 0.15s ease-out;
    transform: translateY(1.92px) translateX(-8.8px) rotate(45deg);
    transform-origin: center center;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    display: block;
    content: '';
    background-color: #191b1e;
  }

  > span::after {
    right: 0;
    transition: all 0.15s ease-out 0.15s, right 0.15s ease-out;
    -webkit-transform: translateY(1.92px) translateX(8.8px) rotate(-45deg);
    transform: translateY(1.92px) translateX(8.8px) rotate(-45deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    display: block;
    content: '';
    background-color: #191b1e;
  }
}

.btn {
  font-size: 13px !important;
  line-height: 48px;
  padding: 0 40px;
  text-align: center;
  color: #191b1e;
  border-radius: 24px;
  background: linear-gradient(40deg, #f5be17, #f9d979);
  background-position: 0 50%;
  background-size: 200% auto;
  margin: 0 !important;
}
.btn:hover {
  background-position: 100% 50%;
  text-decoration: none;
  color: #191b1e !important;
}
