@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.headerItemBadge {
  font-family: 'Roboto', 'Adobe Blank', sans-serif;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 0 4px;
  color: #191b1e;
  background-color: #f5be17;
  border-radius: 100%;
  z-index: 1;
  position: absolute;
  top: -7px;
  right: -10px;
}
