@use 'variables' as *;
@use 'mixins' as *;

$fontstack: 'Roboto', YakuHanJPs, 'Noto Sans JP', 'Adobe Blank', sans-serif;

.activeLink {
  @include mq-min('lg') {
    display: none !important;
  }
}

.navBg {
  visibility: visible;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(25, 27, 30, 0.8);
}

.nav {
  position: fixed;
  z-index: 4;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  transition: all 0.2s ease-out;
  background-color: #fff;
  justify-content: space-between;
  padding: 24px;

  top: 76.8px;
  right: 12.8px;
  width: 75%;
  height: calc(100vh - 89.6px);
  border-radius: 6px;

  @include mq-min('lg') {
    top: 115.2px;
    right: 19.2px;
    width: 25%;
    height: calc(100vh - 134.4px);
    padding: 32px;
    border-radius: 12px;
  }
}

.navList {
  padding-bottom: 24px;
  line-height: 32px !important;

  > a {
    font-weight: 700;
    position: relative;
    display: block;
    padding: 8px;
    color: #191b1e;
    cursor: pointer;
    text-decoration: none;
    font-family: $fontstack;
  }

  > a:hover {
    color: #f5be17;
    text-decoration: none;
  }

  > .btn {
    font-size: 13px !important;
    line-height: 48px;
    padding: 0 40px;
    text-align: center;
    color: #191b1e;
    border-radius: 24px;
    background: linear-gradient(40deg, #f5be17, #f9d979);
    background-position: 0 50%;
    background-size: 200% auto;
    margin: 0 !important;
  }
  > .btn:hover {
    background-position: 100% 50%;
    text-decoration: none;
    color: #191b1e !important;
  }

  > .btn {
    padding: 0 40px;
    margin-top: 16px !important;
    @include mq-min('lg') {
      display: none !important;
    }
  }

  @include mq-min('lg') {
    padding-bottom: 48px;

    > a {
      font-size: 18px;
      line-height: 36px;
      padding-top: 8px;
    }
  }
}

.under {
  @extend .navList;
  padding: 24px 0;
  border-top: 1px solid rgba(25, 27, 30, 0.25);

  @include mq-min('lg') {
    padding-top: 48px;
  }

  > .myAuctions {
    @include mq-min('lg') {
      display: none !important;
    }
  }
}

.snsList {
  display: flex;
  padding-top: 16px;
  border-top: 1px solid rgba(25, 27, 30, 0.25);
  align-items: center;
  justify-content: space-around;

  > a {
    line-height: 32px;
  }

  > a svg {
    width: 24px;
    height: 24px;
    vertical-align: bottom;
  }
}
