@use 'variables' as *;
@use 'mixins' as *;

$fontstack: 'Roboto', YakuHanJPs, 'Noto Sans JP', 'Adobe Blank', sans-serif;

.footerTxt {
  margin-top: 138px;
  text-align: center;
}

.footerSnsList {
  display: flex;
  margin: 24px auto 72px;
  justify-content: space-around;

  @include mq-min('md') {
    margin: 24 0 0 96px;
    justify-content: center;
  }

  @include mq-min('lg') {
    margin: 24px auto 96px;
    justify-content: center;
  }

  > a {
    margin: 0 40px;

    @include mq('md') {
      margin: 0 20px;
    }

    > svg {
      display: block;
      transition: all 0.2s ease-out;
    }

    > svg:hover {
      fill: #191b1e;
    }
  }
}

.footerLinkArea {
  display: flex;
  padding-bottom: 32px;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 32px;
  font-family: $fontstack;

  > a {
    font-size: 10px;
    margin: 0 8px;
    color: rgba(25, 27, 30, 0.5);
    cursor: pointer;
    text-decoration: none;
  }

  > a::selection {
    background-color: rgba(245, 190, 23, 0.2);
  }

  > p {
    font-size: 10px;
    width: 100%;
    text-align: center;
    color: rgba(25, 27, 30, 0.5);

    @include mq-min('lg') {
      margin: 0 32px;
      width: auto;
    }
  }
}
