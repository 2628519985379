@use "reset";
@use 'variables' as *;
@use 'mixins' as *;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, 'BlinkMacSystemFont',
    'Helvetica Neue', Helvetica, 'Arial', 'ヒラギノ角ゴ ProN W3',
    'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: $color-text;
}

@mixin container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.container {
  @include container;
  max-width: $container-width;
}

.container-full {
  @include container;

  @include mq('sm') {
    padding: 0 10px;
  }
}

.container-narrow {
  @include container;
  max-width: $container-width-narrow;
}

/* avoid header */
.page-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: $header-height;
}

/* sticky footer */
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/**
 * always show modal front and center
 */
.ReactModal__Overlay {
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.ReactModal__Body--open {
  overflow: hidden;
}

/**
 * Radio and checkbox buttons
 */
$color1: #ffffff;
$color2: #3197ee;

.radio {
  margin: 0.5rem;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + {
      .radio-label {
        display: block;
        width: 100%;
        cursor: pointer;

        &:before {
          position: relative;
          top: 1px;
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          margin-right: 0.8em;
          text-align: center;
          vertical-align: bottom;
          cursor: pointer;
          content: '';
          background: $color1;
          border: 1px solid darken($color1, 25%);
          border-radius: 100%;
          transition: all 250ms ease;
        }

        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          border-color: $color2;
          outline: none;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          background: darken($color1, 25%);
          border-color: darken($color1, 25%);
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
  }
}

.checkbox {
  margin: 0.5rem;
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    + {
      .checkbox-label {
        display: block;
        width: 100%;
        cursor: pointer;

        &:before {
          position: relative;
          top: 1px;
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          margin-right: 0.8em;
          text-align: center;
          vertical-align: bottom;
          cursor: pointer;
          content: '';
          background: $color1;
          border: 1px solid darken($color1, 25%);
          border-radius: 5px;
          transition: all 250ms ease;
        }

        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
    &:checked {
      + .checkbox-label {
        &:before {
          background: $color2
            url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
            50% 40% no-repeat;
        }
      }
    }
    &:disabled {
      + .checkbox-label {
        &:before {
          background: darken($color1, 25%);
        }
      }
    }
  }
}

// React Datepicker
.react-datepicker-popper {
  z-index: 2 !important;
}
